// Constants
import { NOTE_TEXT, RECRUITMENT_TITLE, RECRUITMENT_STEPS } from '../constants/homePage';

// Utility Packages
import { Card } from 'primereact/card';

function RecruitmentProcess() {
  return (
    <section className="recruitment-process-section">
      <div className="container">
        <h2>{RECRUITMENT_TITLE}</h2>
        <div className="recruitment-process-wrapper">
          {RECRUITMENT_STEPS.map((step, index) => (
            <div
              key={index}
              className={`recruitment-process-col ${step.blockClass ? step.blockClass : ''}`}>
              <Card>
                <em>
                  <img src={step.image} alt={step.alt} />
                </em>
                <h3>
                  {step.title}
                  {step.subtitle && <span>{step.subtitle}</span>}
                </h3>
                <p>{step.description}</p>
              </Card>
            </div>
          ))}
        </div>
        <p className="note-txt">{NOTE_TEXT}</p>
      </div>
    </section>
  );
}

export default RecruitmentProcess;
