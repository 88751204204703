import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { PAGINATION_OPTIONS } from '../constants/general';

const CustomDataTable = ({
  data = [],
  columns = [],
  totalRecords = 0,
  searchValue = '',
  dropdown,
  dropdownValue,
  dropdownPlaceHolder = 'Status',
  searchPlaceHolder = 'Search',
  rowPerPageOption = PAGINATION_OPTIONS,
  onSort,
  onPagination,
  onSearch,
  onStatusFilter,
  isDropdownMultiSelect = false,
  isdropdownGroup = false,
  disablePaginator = false,
  isHeaderDisable = false
}) => {
  const tableRef = useRef(null);
  const [lazyParams, setLazyParams] = useState({ first: 0, page: 1 });
  const [selectedLimit, setSelectedLimit] = useState(null);
  const [search, setSearch] = useState(searchValue);

  const handlePageChange = (e) => {
    setLazyParams(e);
    setSelectedLimit(e.rows);
    onPagination && onPagination(e.page + 1, e.rows);
  };

  const handleSort = (e) => {
    const fieldName = e.multiSortMeta[0].field;
    const sortColumn = columns.find((col) => col.field === fieldName);
    sortColumn && onSort && onSort(sortColumn.sortBy);
  };

  const tableHeader = !isHeaderDisable && (
    <div className="table-header flex justify-content-between">
      {onSearch && (
        <div className="p-input-icon-left search-input">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={search}
            onInput={(e) => {
              setSearch(e.target.value);
              onSearch(e.target.value);
            }}
            placeholder={searchPlaceHolder}
          />
        </div>
      )}
      {dropdown && (
        <div className="status-select-dropdown">
          {isDropdownMultiSelect ? (
            <MultiSelect
              filter
              value={dropdownValue}
              className="custom-dropdown"
              options={dropdown}
              onChange={(e) => onStatusFilter && onStatusFilter(e.value || '')}
              maxSelectedLabels={2}
              display="chip"
              optionLabel="name"
              optionValue="value"
              placeholder={dropdownPlaceHolder}
              {...(isdropdownGroup && { optionGroupLabel: 'label', optionGroupChildren: 'items' })}
            />
          ) : (
            <Dropdown
              value={dropdownValue}
              className="custom-dropdown"
              options={dropdown}
              onChange={(e) => onStatusFilter && onStatusFilter(e.value || null)}
              showClear
              optionLabel="name"
              placeholder={dropdownPlaceHolder}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="white-box custom-table-wrapper p-0 overflow-hidden">
      <DataTable
        size="small"
        ref={tableRef}
        value={data}
        header={tableHeader}
        totalRecords={totalRecords}
        paginator={!disablePaginator}
        rows={selectedLimit || 10}
        lazy
        first={lazyParams.first}
        onPage={handlePageChange}
        onSort={handleSort}
        showGridlines
        stripedRows
        responsiveLayout="scroll"
        rowsPerPageOptions={rowPerPageOption}
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        sortMode="multiple">
        {columns.map(({ field, header, sortBy, class: columnClass, width }) => (
          <Column
            key={field}
            field={field}
            header={header}
            sortable={!!sortBy}
            className={columnClass}
            style={width ? { width } : {}}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default CustomDataTable;
