import React from 'react';

// Utility Packages
import { Avatar } from 'primereact/avatar';

const AvtarImage = (props) => {
  const { firstName, lastName } = props;
  const fName = firstName || '';
  const lName = lastName || '';
  return (
    <Avatar
      shape="circle"
      className={props.className}
      style={{ backgroundColor: '#7367f0', color: '#ffffff' }}
      label={`${fName.charAt(0).toLocaleUpperCase()}${lName.charAt(0).toLocaleUpperCase()}`}
    />
  );
};

export default AvtarImage;
