// Utility Functions
import { getAccessToken } from './session';

// Utility Packages
import axios from 'axios';

export default function API(method, endPoint, data) {
  return axios({
    method: method,
    url: endPoint,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
    data: data,
    params: data && data.params ? data.params : null
  });
}

export { API };
