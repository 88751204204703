import { useEffect, useRef, useState } from 'react';

// Components
import Label from '../../components/Label';
import ProfileUpdatePassword from '../../components/ProfileUpdatePassword';

// Constants
import { API_REQUEST, LABELS, ROUTES } from '../../constants/general';
import { PROFILE_API, TECHNOLOGY_API } from '../../constants/configs';
import { profileValidationSchema } from '../../constants/schemas';

// Images
import AvtarImage from '../../components/AvtarImage';

// Utility Functions
import { getLocalStorageUserData, setLocalStorageUserData } from '../../utils/session';
import { getFormErrorMessage, isFormFieldValid, openResumeLink } from '../../utils/general';
import { handleError, handleSuccess } from '../../utils/toast';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';

// Utility Packages
import { useFormik } from 'formik';
import API from '../../utils/api';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';

const profileImagePath = `${process.env.REACT_APP_PROFILE_IMAGE_URL}/public/uploads/profile`;
const resumePath = `${process.env.REACT_APP_PROFILE_IMAGE_URL}/public/uploads/resume`;

function Profile() {
  const navigate = useNavigate();

  // State
  const [imagePrev, setImagePrev] = useState(null);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    address: '',
    technology: [],
    profileImage: '',
    resumeFilePath: ''
  });
  const [removeImage, setRemoveImage] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isFile, setIsFile] = useState('');
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  // React useRef hook
  const fileRef = useRef(null);
  const resumeFileRef = useRef(null);

  // State
  const [technologies, setTechnologies] = useState([]);

  const { firstName, lastName, id } = getLocalStorageUserData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData,
    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      updateProfileData(values);
    }
  });

  const userName = `${firstName || ''} ${lastName || ''}`;

  useEffect(() => {
    getTechnology();
    getProfileData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProfileData = () => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.get, PROFILE_API)
      .then((res) => {
        handleSuccess(toastReff, res);
        dispatch(Actions.User.SetLoading, false);
        const {
          FirstName,
          LastName,
          Email,
          Gender,
          Mobile,
          Address,
          UserProfile,
          UMap,
          ResumeOfCandidate
        } = res?.data?.data?.data;

        const userData = getLocalStorageUserData();
        setLocalStorageUserData({ ...userData, userProfile: UserProfile });
        setUserData({
          firstName: FirstName,
          lastName: LastName,
          email: Email,
          phone: Mobile,
          gender: Gender,
          address: Address,
          technology: UMap && UMap.length > 0 ? UMap?.map((tech) => tech.MapT?.ID) : [],
          profileImage: UserProfile
        });
        setIsFile(ResumeOfCandidate?.FilePath || '');
        setFileName(
          ResumeOfCandidate?.FilePath
            ? ResumeOfCandidate?.FilePath?.split('-').slice(2).join('')
            : ''
        );
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  const getTechnology = () => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.get, TECHNOLOGY_API)
      .then((res) => {
        dispatch(Actions.User.SetLoading, false);
        setTechnologies(res?.data?.data?.data || []);
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        console.log(error);
      });
  };

  const updateProfileData = (data) => {
    dispatch(Actions.User.SetLoading, true);

    const payload = new FormData();
    Object.keys(data).forEach((el) => {
      if (el === 'profileImage' && imagePrev) {
        payload.append('profileImage', data.profileImage);
      } else if (el === 'resumeFilePath' && fileName) {
        payload.append('resumeFilePath', data.resumeFilePath);
      } else if (el === 'technology') {
        for (var i = 0; i < data[el].length; i++) {
          payload.append('technology[]', data[el][i]);
        }
      } else if (typeof el === 'string') {
        payload.append(el, data[el]);
      } else {
        payload.append(el, JSON.stringify(data[el]));
      }
    });

    API(API_REQUEST.put, PROFILE_API, payload)
      .then((res) => {
        handleSuccess(toastReff, res);
        formik.setFieldValue('profileImage', '');
        formik.setFieldValue('resumeFilePath', '');
        setImagePrev('');
        setFileName('');
        getProfileData();
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  const imageName = userData?.profileImage || '';
  const encodedPath = imageName
    .split('/')
    .map((p) => encodeURIComponent(p))
    .join('/');

  useEffect(() => {
    if (removeImage === true) {
      formik.setFieldValue('profileImage', null);
      setImagePrev(null);
    }
  }, [removeImage, formik]);

  const removeImageConfirmation = () => {
    confirmDialog({
      header: 'Delete',
      message: 'Are you sure you want to delete profile image?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => setRemoveImage(true)
    });
  };

  return (
    <>
      <div className="user-profile-wrapper">
        <form onSubmit={formik.handleSubmit} className="form-main" encType="multipart/form-data">
          <div className="left-col">
            <div className="form-col">
              <div className="profile-box">
                <div className="box-inner">
                  <div className="profile-img">
                    {userData?.profileImage && !imagePrev && !removeImage ? (
                      <img
                        src={`${profileImagePath}/${id}/${encodedPath}`}
                        alt="profile"
                        className="user-img"
                      />
                    ) : !imagePrev || removeImage ? (
                      <AvtarImage className="user-img" firstName={firstName} lastName={lastName} />
                    ) : (
                      <img src={imagePrev} alt="profile" className="user-img" />
                    )}
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      id="profileImage"
                      name="profileImage"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          formik.setFieldValue('profileImage', e.target.files[0]);
                          if (
                            ['image/png', 'image/jpeg', 'image/jpg'].includes(
                              e.target.files[0].type
                            )
                          )
                            setImagePrev(URL.createObjectURL(e.target.files[0]));
                          else setImagePrev(false);
                          setRemoveImage(false);
                        }
                      }}
                      accept="image/png, image/jpeg ,image/jpg"
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'profileImage')
                      })}
                    />

                    <div className="user-details">
                      <div className="user-name">{userName}</div>
                    </div>
                  </div>
                  <div className="edit-delete-btn">
                    <Button
                      type="button"
                      className="border-none edit-btn"
                      icon="pi pi-pencil"
                      label={!userData?.profileImage ? 'Upload' : 'Change'}
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    />
                    <Button
                      disabled={!userData?.profileImage ? 'disabled' : ''}
                      className="border-none delete-btn"
                      icon="pi pi-trash"
                      label="Delete"
                      type="button"
                      onClick={() => removeImageConfirmation()}
                    />
                  </div>
                  {
                    <small className="p-error">
                      {formik.errors['profileImage'] && formik.errors['profileImage']}
                    </small>
                  }
                </div>
              </div>
              <div className="notification-outer">
                <div className="notification-div reset-password-div">
                  <div
                    className="text-right font-medium no-underline text-blue-500 cursor-pointer primary-link"
                    onClick={() => setShowChangePasswordPopup(true)}>
                    Change Password
                  </div>
                </div>
              </div>
            </div>
            <div className="form-col resume-col">
              <div className="profile-box">
                <div className="box-inner">
                  <div className="title-wrapper">
                    <h3 style={{ textAlign: 'center' }}>Resume</h3>
                    <div className="edit-delete-btn">
                      <Button
                        type="button"
                        className="border-none edit-btn"
                        icon="pi pi-eye"
                        label="Preview"
                        onClick={() => {
                          if (formik.values.resumeFilePath || isFile) {
                            let fileUrl = '';
                            if (formik.values.resumeFilePath) {
                              fileUrl = URL.createObjectURL(formik.values.resumeFilePath);
                            } else {
                              fileUrl = `${resumePath}/${id}/${isFile}`;
                            }
                            openResumeLink(fileUrl);
                          }
                        }}
                      />
                      <Button
                        disabled={!formik.values.resumeFilePath ? 'disabled' : ''}
                        className="border-none delete-btn"
                        icon="pi pi-trash"
                        label="Delete"
                        type="button"
                        onClick={() => {
                          formik.setFieldValue('resumeFilePath', '');
                          setFileName(isFile ? isFile?.split('-').slice(2).join('') : '');
                          resumeFileRef.current.value = null;
                        }}
                      />
                    </div>
                  </div>
                  <div className="profile-img">
                    <input
                      hidden
                      ref={resumeFileRef}
                      type="file"
                      id="resumeFilePath"
                      name="resumeFilePath"
                      onChange={(e) => {
                        formik.setFieldValue('resumeFilePath', e.target.files[0]);
                        setFileName(e.target.files[0].name);
                      }}
                      accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'resumeFilePath')
                      })}
                    />
                    <div className="user-details">
                      <div className="user-name">{fileName || 'Not Available'}</div>
                    </div>
                  </div>
                  <div className="upload-resume">
                    <Button
                      type="button"
                      className="border-none edit-btn border-btn"
                      icon="pi pi-upload"
                      label={!formik.values?.resumeFilePath ? 'Upload Your Resume Now' : 'Change'}
                      onClick={() => {
                        resumeFileRef.current.click();
                      }}
                    />
                  </div>
                  {
                    <small className="p-error">
                      {formik.errors['resumeFilePath'] && formik.errors['resumeFilePath']}
                    </small>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="right-col white-box">
            <div className="form-row-wrapper">
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="firstName" text={LABELS.INPUT.FIRSTNAME} isBold />
                    <InputText
                      id="firstName"
                      name="firstName"
                      placeholder={LABELS.INPUT.FIRSTNAME}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'firstName')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'firstName')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="lastName" text={LABELS.INPUT.LASTNAME} isBold />
                    <InputText
                      id="lastName"
                      name="lastName"
                      placeholder={LABELS.INPUT.LASTNAME}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'lastName')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'lastName')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold />
                    <InputText
                      id="email"
                      name="email"
                      placeholder={LABELS.INPUT.EMAIL}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'email')
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="phone" text={LABELS.INPUT.PHONE} isBold />
                    <InputText
                      id="phone"
                      name="phone"
                      placeholder={LABELS.INPUT.PHONE}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'phone')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'mobile')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="technology" text={LABELS.INPUT.TECHNOLOGY} isBold />
                    <MultiSelect
                      filter
                      name="technology"
                      id="technology"
                      optionLabel="Technology"
                      optionValue="ID"
                      value={formik.values.technology}
                      forceSelection
                      options={technologies}
                      onChange={formik.handleChange}
                      display="chip"
                      onBlur={formik.handleBlur}
                      placeholder="Select Technology *"
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'technology'),
                        'w-full': true
                      })}
                      panelClassName="technology-overlay"
                    />
                  </div>
                  {getFormErrorMessage(formik, 'technology')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="address" text={LABELS.INPUT.ADDRESS} isBold />
                    <InputTextarea
                      placeholder={LABELS.INPUT.ADDRESS}
                      labelText={LABELS.INPUT.ADDRESS}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={formik.touched['address'] ? formik.errors['address'] : ''}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'address')
                      })}
                      rows={5}
                      cols={30}
                      id="address"
                      name="address"
                    />
                  </div>
                  {getFormErrorMessage(formik, 'address')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group margin-bottom">
                    <Label htmlFor="gender" text={LABELS.INPUT.GENDER} isMandatory isBold />
                    <div className="radio-list-wrapper">
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="male"
                          value={1}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 1}
                        />
                        <Label htmlFor="male" text={LABELS.INPUT.MALE} isBold />
                      </div>
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="female"
                          value={2}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 2}
                        />
                        <Label htmlFor="female" text={LABELS.INPUT.FEMALE} isBold />
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage(formik, 'gender')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Button type="submit" variant="contained" className="ims-blue-btn">
                {LABELS.BUTTON.SAVE}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(ROUTES.DASHBOARD)}
                color="error"
                className="gray-btn border-btn">
                {LABELS.BUTTON.CANCEL}
              </Button>
            </div>
          </div>
        </form>
      </div>

      <ProfileUpdatePassword
        show={showChangePasswordPopup}
        onHide={(value) => setShowChangePasswordPopup(value)}
      />
    </>
  );
}

export default Profile;
