export const trimObjectValues = (obj, excludedKey = '') => {
  // Add excludedKey parameter
  for (let key in obj) {
    if (key !== excludedKey) {
      // Skip excludedKey
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        trimObjectValues(obj[key], excludedKey); // Recursively trim values of nested objects
      }
    }
  }
};

export const isFormFieldValid = (formik, name) =>
  !!(formik?.touched?.[name] && formik?.errors?.[name]);

export const getFormErrorMessage = (formik, name) => {
  return (
    isFormFieldValid(formik, name) && <small className="p-error">{formik?.errors?.[name]}</small>
  );
};

export function getFormattedDateView(dateArg) {
  const newDate = new Date(dateArg);
  var month = newDate.toLocaleString('default', { month: 'short' });
  let year = newDate.getFullYear();
  let date = newDate.getDate();
  let formattedDate = (date > 9 ? '' : '0') + date + ' ' + month + ' ' + year;
  return formattedDate;
}

// Convert 24Hours Time into 12Hours
export function formatTime(isoDate) {
  const date = new Date(isoDate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 === 0 ? '12' : hours % 12;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const wordCapitalize = (word) => {
  return `${word?.charAt(0).toUpperCase()}${word?.slice(1)}`;
};

export const openResumeLink = (link) => window.open(link, '_blank');

export const ResumeButton = (props) => {
  return (
    <>
      <i data-tip="View" onClick={props.onClick} className="pi pi-fw pi-eye mr-2 cursor-pointer" />
    </>
  );
};

export const resumeValidation = (value) => {
  return (
    value &&
    (value.type === '.doc' ||
      value.type === '.docx' ||
      value.type === 'application/pdf' ||
      value.type === 'application/msword' ||
      value.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
  );
};
