// Constants
import { ROUTES } from '../../constants/general';

// Utility Packages
import { Navigate } from 'react-router-dom';

// Utility Functions
import { getAccessToken } from '../../utils/session';

// Access of Routes For logged in user
const PrivateRoute = ({ children }) => {
  const isLogin = getAccessToken();

  return isLogin ? children : <Navigate to={ROUTES.LOGIN} />;
};
export default PrivateRoute;
