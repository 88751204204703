import React from 'react';

// Utility Packages
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

// Utility Functions
import API from '../utils/api';
import { handleError, handleSuccess } from '../utils/toast';
import { dispatch } from '../utils/store';

// Constants
import { changePasswordValidationSchema } from '../constants/schemas';
import { getFormErrorMessage, isFormFieldValid } from '../utils/general';
import { API_REQUEST, LABELS } from '../constants/general';
import { CHANGE_PASSWORD_API } from '../constants/configs';
import { Actions } from '../redux/actions';

// Components
import Label from './Label';

const ProfileUpdatePassword = (props) => {
  const { onHide, show } = props;

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values) => {
      changePassword(values.oldPassword, values.password);
    }
  });

  const changePassword = (oldPassword, newPassword) => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.put, CHANGE_PASSWORD_API, {
      currentPassword: oldPassword,
      newPassword
    })
      .then((res) => {
        handleSuccess(toastReff, res);
        dispatch(Actions.User.SetLoading, false);
        onHide(false);
      })
      .catch((error) => {
        handleError(toastReff, error);
        dispatch(Actions.User.SetLoading, false);
        console.log(error);
      });
  };

  return (
    <>
      <Sidebar
        visible={show}
        onHide={() => onHide(false)}
        position="right"
        className="sidebar-drawer">
        <div className="form-box-wrapper change-password-form">
          <div className="title-wrapper">
            <p className="card-title">Change Password</p>
            <button className="p-sidebar-close" onClick={() => onHide(false)}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="form-row-wrapper form-row-wrap">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="oldPassword" text={LABELS.INPUT.OLD_PASSWORD} isMandatory />
                    <Password
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Old Password *"
                      value={formik.values.oldPassword}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'oldPassword')
                      })}
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'oldPassword')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="newPassword" text={LABELS.INPUT.NEW_PASSWORD} isMandatory />
                    <Password
                      htmlFor="password"
                      text={LABELS.INPUT.NEW_PASSWORD}
                      ismandatory
                      isbold
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({ 'p-invalid': isFormFieldValid(formik, 'password') })}
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'password')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="confirmPassword *"
                      text={LABELS.INPUT.CONFIRM_PASSWORD}
                      isMandatory
                    />
                    <Password
                      htmlFor="confirmPassword"
                      text={LABELS.INPUT.CONFIRM_PASSWORD}
                      ismandatory
                      isbold
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'confirmPassword')
                      })}
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'confirmPassword')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Button
                type="button"
                variant="contained"
                className="ims-blue-btn"
                onClick={formik.handleSubmit}>
                {LABELS.BUTTON.UPDATE_PASSWORD}
              </Button>
              <Button
                variant="contained"
                onClick={() => onHide(false)}
                color="error"
                className="gray-btn border-btn">
                {LABELS.BUTTON.CANCEL}
              </Button>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default ProfileUpdatePassword;
