// Images
import dmcalogo from '../assets/images/dmca-protected-sml-120m.png';

// Constants
import { FOOTER_LINKS, SOCIAL_LINKS } from '../constants/homePage';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-lower-part">
        <div className="container">
          <div className="footer-lower-content-wrapper">
            <div className="copy-right">
              <ul>
                {FOOTER_LINKS.map((link, index) => (
                  <li key={index}>
                    <a href={link.href} title={link.title}>
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
              <p>
                Copyright © 2000-2024.{' '}
                <a href="#" title="TatvaSoft Software Development Company">
                  TatvaSoft Software Development Company
                </a>
              </p>
            </div>
            <div className="social-icons">
              <ul>
                {SOCIAL_LINKS.map((social, index) => (
                  <li key={index}>
                    <a href={social.href} target="_blank" rel="noreferrer" title={social.title}>
                      <img src={social.icon} alt={social.title} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dmca-logo">
              <a href="#" title="DMCA.com Protection Status">
                <img src={dmcalogo} alt="dmca-logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
