// Components
import Header from '../../components/Header';
import BannerDescrption from '../../components/BannerDescription';
import JobsAtTatvasoft from '../../components/JobsAtTatvasoft';
import BenifitsAtTatvasoft from '../../components/BenifitsAtTatvasoft';
import RecruitmentProcess from '../../components/RecruitmentProcess';
import Faq from '../../components/Faq';
import CareerInfo from '../../components/CareerInfo';
import CtaBlock from '../../components/CtaBlock';
import Footer from '../../components/Footer';

function Home() {
  return (
    <>
      <div className="main-site-wrapper">
      <Header />
      <main className='main-wrapper'>
        <BannerDescrption />
        <JobsAtTatvasoft />
        <BenifitsAtTatvasoft/>
        <RecruitmentProcess/>
        <Faq />
        <CareerInfo/>
        <CtaBlock/>
      </main>
      <Footer/>
      </div>
    </>
  );
}

export default Home;
