import * as Yup from 'yup';
import { resumeValidation } from '../utils/general';

// Login
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  password: Yup.string().trim().required('Password is a required field.')
});

// Register
export const registerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Firstname can consist of only alphanumeric characters.')
    .required('Firstname is a required field.')
    .min(3, 'Firstname should have minimum 3 characters.')
    .max(100, 'Firstname can have maximum 100 characters.'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Lastname can consist of only alphanumeric characters.')
    .required('Lastname is a required field.')
    .min(3, 'Lastname should have minimum 3 characters.')
    .max(100, 'Lastname can have maximum 100 characters.'),
  email: Yup.string()
    .trim()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  password: Yup.string()
    .trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number).'
    ),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm password is a required field.')
    .oneOf([Yup.ref('password'), null], 'Password does not match.'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone must be numeric value.')
    .length(10, 'Phone should have 10 digits.')
    .required('Phone is a required field.')
});

// Forgot Password
export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .email('This email is not a valid format.')
    .required('Email is a required field.')
});

// Reset Password
export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number).'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required.')
    .oneOf([Yup.ref('password'), null], 'Password does not match.')
});

// Profile
export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Firstname can consist of only alphanumeric characters.')
    .required('Firstname is a required field.')
    .min(3, 'Firstname should have minimum 3 characters.')
    .max(100, 'Firstname can have maximum 100 characters.'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Lastname can consist of only alphanumeric characters.')
    .required('Lastname is a required field.')
    .min(3, 'Lastname should have minimum 3 characters.')
    .max(100, 'Lastname can have maximum 100 characters.'),
  email: Yup.string()
    .trim()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone must be numeric value.')
    .length(10, 'Phone should have 10 digits.')
    .required('Phone is a required field.'),
  profileImage: Yup.mixed().test(
    'format',
    'Only the following formats are accepted: .jpeg, .jpg, .png',
    (value) => {
      if (!value) {
        return true;
      }
      return (
        value ||
        (typeof value === 'object' &&
          (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png'))
      );
    }
  ),
  resumeFilePath: Yup.mixed().test(
    'format',
    'Only the following formats are accepted: .doc, .docx, .pdf',
    (value) => {
      if (!value) {
        return true;
      }
      return resumeValidation(value);
    }
  )
});

// Profile
export const changePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string().required('Old Password is required.'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-z]).*$/, 'Password must contain at least one lowercase letter.')
    .matches(/^(?=.*[A-Z]).*$/, 'Password must contain at least one uppercase letter.')
    .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit.')
    .matches(
      /^(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).*$/,
      'Password must contain at least one special character.'
    )
    .matches(/^\S*$/, 'Password cannot contain spaces.'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
