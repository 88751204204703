export default function auth(
  initialState = {
    isLogged: false,
    loading: false
  },
  action
) {
  switch (action.type) {
    case 'SET_USER_IS_LOGGED_IN':
      return {
        ...initialState,
        isLogged: action.payload
      };

    case 'SET_USER_IS_LOADING':
      return {
        ...initialState,
        loading: action.payload
      };

    case 'USER_RESET':
      return {
        ...initialState,
        isLogged: false,
        loading: false
      };

    default:
      return initialState;
  }
}
