import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// Images
import topCurve from '../assets/images/top-curve.svg';
import bottomCurve from '../assets/images/bottom-curve.svg';

// Components
import Topbar from './Topbar';
import Sidebar from './Sidebar';

// Constants
import { SIDEBAR_MENU } from '../constants/general';

// Utility Packages
import classNames from 'classnames';

const Layout = () => {
  const layoutMode = 'static'; // overlay / static
  const layoutColorMode = 'light'; // light / dark
  const inputStyle = useState('outlined'); // outlined / filled
  const ripple = false; // true / false
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const location = useLocation();
  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    mobileMenuActive
      ? addClass(document.body, 'body-overflow-hidden')
      : removeClass(document.body, 'body-overflow-hidden');
  }, [mobileMenuActive]);

  const onWrapperClick = () => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => window.innerWidth >= 992;

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light'
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Topbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div
        className="layout-sidebar"
        onClick={onSidebarClick}
        onMouseEnter={() => {
          if (document.body.classList.contains('sidebar-hide')) {
            document.body.classList.add('sidebar-hover');
          }
        }}
        onMouseLeave={() => {
          document.body.classList.remove('sidebar-hover');
        }}>
        <Sidebar
          model={SIDEBAR_MENU}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>
      <div className="top-curve sidebar-curve">
        <img src={topCurve} className="light-theme-curve" />
      </div>
      <div className="bottom-curve sidebar-curve">
        <img src={bottomCurve} className="light-theme-curve" />
      </div>
      <div className="layout-main-container">
        <div className="layout-main relative">
          <div className="mobile-portal-title">
            <span className="logo-text">{location?.pathname?.toString().split('/')?.at(-2)}</span>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
