export const Actions = {
  User: {
    SetIsUserLogged: 'SET_USER_IS_LOGGED_IN',
    SetLoading: 'SET_USER_IS_LOADING',
    UserReset: 'USER_RESET'
  },
  Interviews: {
    SetInterviews: 'SET_INTERVIEWS',
    SetPageNumber: 'SET_INTERVIEWS_PAGE_NUMBER',
    SetPageSize: 'SET_INTERVIEWS_PAGE_SIZE',
    SetTotalRecords: 'SET_INTERVIEWS_TOTAL_RECORDS',
    SetSortBy: 'SET_INTERVIEWS_SORT_BY',
    SetOrderBy: 'SET_INTERVIEWS_ORDER_BY',
    InterviewsReset: 'INTERVIEWS_RESET'
  },
  Notifications: {
    SetNotificationsRef: 'SET_NOTIFICATIONS_TAOST_REF',
    NotificationsReset: 'NOTIFICATIONS_RESET'
  }
};
