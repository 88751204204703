// Utility Functions
import { BENEFITS, BENEFITS_WITH_TATVASOFT_TITLE } from '../constants/homePage';

// Utility Packages
import { Card } from 'primereact/card';

function BenefitsAtTatvasoft() {
  return (
    <section className="tatva-career-benefit">
      <div className="container">
        <div className="title-block">
          <h2>{BENEFITS_WITH_TATVASOFT_TITLE}</h2>
        </div>
        <div className="work-grid-wrapper">
          {BENEFITS.map((benefit, index) => (
            <div className="work-item" key={index}>
              <Card>
                <em>
                  <img src={benefit.image} alt={benefit.alt} />
                </em>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default BenefitsAtTatvasoft;
