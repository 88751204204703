// Constants
import { ROUTES } from '../../constants/general';

// Utility Packages
import { Navigate } from 'react-router-dom';

// Utility Functions
import { getAccessToken } from '../../utils/session';

// For public user
const PublicRoute = ({ children }) => {
  const isLogin = getAccessToken();

  return !isLogin ? children : <Navigate to={ROUTES.DASHBOARD} />;
};
export default PublicRoute;
