import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

function Loader() {
  return (
    <div className="loader">
      <ProgressSpinner strokeWidth={3} />
    </div>
  );
}

export default Loader;
