import { INITIAL_PAGE_NUMBER, INITIAL_SIZE } from '../../constants/general';

export default function interviews(
  initialState = {
    interviews: [],
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    totalRecords: 0,
    sortBy: 'StartDateOfInterviewRound',
    orderBy: 'DESC'
  },
  action
) {
  switch (action.type) {
    case 'SET_INTERVIEWS':
      return {
        ...initialState,
        interviews: action.payload
      };

    case 'SET_INTERVIEWS_PAGE_NUMBER':
      return {
        ...initialState,
        pageNumber: action.payload
      };

    case 'SET_INTERVIEWS_PAGE_SIZE':
      return {
        ...initialState,
        size: action.payload
      };

    case 'SET_INTERVIEWS_SORT_BY':
      return {
        ...initialState,
        sortBy: action.payload
      };

    case 'SET_INTERVIEWS_ORDER_BY':
      return {
        ...initialState,
        orderBy: action.payload
      };

    case 'SET_INTERVIEWS_TOTAL_RECORDS':
      return {
        ...initialState,
        totalRecords: action.payload
      };

    case 'INTERVIEWS_RESET':
      return {
        ...initialState,
        interviews: [],
        pageNumber: INITIAL_PAGE_NUMBER,
        size: INITIAL_SIZE,
        totalRecords: 0,
        sortBy: 'DateModified',
        orderBy: 'DESC'
      };

    default:
      return initialState;
  }
}
