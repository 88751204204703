// Constants
import { FAQ_DATA, FAQ_HEADER, FAQ_TITLE } from '../constants/homePage';

// Utility Packages
import { Accordion, AccordionTab } from 'primereact/accordion';

function Faq() {
  return (
    <section className="tatva-faq-section">
      <div className="container">
        <h2>{FAQ_HEADER}</h2>
        <p>{FAQ_TITLE}</p>
        <Accordion>
          {FAQ_DATA.map((faq, index) => (
            <AccordionTab key={index} header={faq.header}>
              <p>{faq.content}</p>
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    </section>
  );
}

export default Faq;
