import React, { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Images
import siteLogo from '../assets/images/site-logo.svg';
import AvtarImage from './AvtarImage';

// Constants
import { ROUTES } from '../constants/general';

//  Utility Packages
import classNames from 'classnames';
import { Menu } from 'primereact/menu';

// Utility Functions
import { getLocalStorageUserData, removeSessionData } from '../utils/session';

const Topbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname?.toString().split('/').at(-1);
  const menuRef = useRef(null);

  const { firstName, lastName, id, userProfile } = getLocalStorageUserData();

  const userName = `${firstName || ''} ${lastName || ''}`;

  const logout = () => {
    removeSessionData();
    navigate(ROUTES.LOGIN);
  };

  const items = [
    {
      label: 'My Profile',
      icon: 'pi pi-user',
      command: () => navigate(ROUTES.PROFILE)
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => logout()
    }
  ];

  return (
    <>
      <div className="layout-topbar-block">
        <div className="layout-topbar">
          <div className="layout-topbar-logo">
            <button
              type="button"
              className="p-link layout-menu-button layout-topbar-button"
              onClick={props.onToggleMenuClick}>
              <i className="pi pi-bars default-ic" />
              <i className="pi pi-times active-menu-ic" />
            </button>
            <span className="logo-text">{path}</span>
            <Link to={ROUTES.DASHBOARD} className="topbar-mobile-logo">
              <img src={siteLogo} className="light-theme-logo" />
            </Link>
          </div>

          <button
            type="button"
            className="p-link layout-topbar-menu-button layout-topbar-button"
            onClick={(event) => menuRef.current.toggle(event)}>
            <i className="pi pi-ellipsis-v" />
          </button>

          <div
            className={classNames('layout-topbar-menu lg:flex origin-top', {
              'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive
            })}>
            <div className="flex align-items-center topbar-menu-list">
              <div
                className="flex align-items-center topbar-menu-item profile-wrapper"
                onClick={(event) => menuRef.current.toggle(event)}>
                <span className="user-role">{userName}</span>
                <Menu
                  model={items}
                  popup
                  ref={menuRef}
                  id="popup_menu"
                  className="popup_menu user-menu-popup"
                />
                <div className="user-dropdown">
                  <div className="menu-link">
                    {userProfile ? (
                      <img
                        src={`${
                          process.env.REACT_APP_PROFILE_IMAGE_URL
                        }/public/uploads/profile/${id}/${userProfile
                          .split('/')
                          .map((p) => encodeURIComponent(p))
                          .join('/')}`}
                        alt="profile"
                        className="border-circle user-img"
                      />
                    ) : (
                      <AvtarImage className="user-img" firstName={firstName} lastName={lastName} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
