import { Card } from 'primereact/card';

// Constants
import {
  EXPERIENCE,
  JOB_APPLY_NOW,
  JOB_READ_MORE,
  jobs,
  JOBS_TITLE,
  POSITION
} from '../constants/homePage';

function JobsAtTatvasoft() {
  return (
    <section className="tatva-job-section">
      <div className="container">
        <div className="title-block">
          <h2>{JOBS_TITLE}</h2>
        </div>
        <div className="job-grid-wrapper">
          {jobs.map((job, index) => (
            <div className="job-grid" key={index}>
              <Card>
                <em>
                  <img src={job.image} alt={job.title} />
                </em>
                <h3>{job.title}</h3>
                <div className="exp-wrapper">
                  <p>
                    {POSITION} : <span>{job.position}</span>
                  </p>
                  <p>
                    {EXPERIENCE} : <span>{job.experience}</span>
                  </p>
                </div>
                <div className="btn-wrapper">
                  <a href="#" title={JOB_READ_MORE} className="btn read-more-btn">
                    {JOB_READ_MORE}
                  </a>
                  <a href="#" title={JOB_APPLY_NOW} className="btn yellow-btn">
                    {JOB_APPLY_NOW}
                  </a>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default JobsAtTatvasoft;
