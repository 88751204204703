// Components
import Asteric from './Asteric';

import { classNames } from 'primereact/utils';

const Label = (props) => {
  const { htmlFor, className, text, isMandatory, isBold, ishidden, onFocus, onBlur } = props;
  return (
    <label
      htmlFor={htmlFor}
      className={[
        !isBold ? 'text-gray font-bold' : '',
        ishidden ? 'hide-label' : '',
        className || ''
      ].join(' ')}>
      <span
        className={classNames({
          'input-focus': onFocus,
          'text-gray': onBlur
        })}>
        {text}
      </span>
      {isMandatory && <Asteric />}
    </label>
  );
};

export default Label;
