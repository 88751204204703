// Constants
import {
  BANNER_DESCRIPTION,
  BANNER_HEADING,
  CAREER_DESCRIPTION,
  CTA_EMAIL,
  CTA_EMAIL_TITLE,
  WARNING_MESSAGE
} from '../constants/homePage';

// Images
import bannerMobileJpg from '../assets/images/banner-career-mob.jpg';
import bannerMobileWebp from '../assets/images/banner-career-mob.webp';
import bannerJpg from '../assets/images/banner-career.jpg';
import bannerWebp from '../assets/images/banner-career.webp';
import warningIcon from '../assets/images/warning-image.svg';

function BannerDescription() {
  return (
    <>
      <section className="page-banner-section">
        <picture className="tatvasoft-inner-banner">
          <source media="(min-width: 768px)" srcSet={`${bannerWebp}, ${bannerJpg}`} />
          <source srcSet={`${bannerMobileWebp}, ${bannerMobileJpg}`} />
          <img src={bannerMobileJpg} alt="Banner Image" />
        </picture>
        <div className="banner-content-wrapper">
          <div className="container">
            <div className="banner-content">
              <h1>{BANNER_HEADING}</h1>
              <p>{BANNER_DESCRIPTION}</p>
            </div>
          </div>
        </div>
      </section>

      <div className="warning-msg-block">
        <div className="container">
          <div className="warning-content-wrapper">
            <img src={warningIcon} alt="Warning message" />
            <p>
              {WARNING_MESSAGE}
              <a href={`mailto:${CTA_EMAIL}`} title={CTA_EMAIL_TITLE}>
                {CTA_EMAIL}
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="tatva-career-description">
        <div className="container">
          <p>{CAREER_DESCRIPTION}</p>
        </div>
      </div>
    </>
  );
}

export default BannerDescription;
