export const LABELS = {
  WELCOME: 'Welcome to IMS portal!',
  KEEP_ME_SIGNED_IN: 'Keep me signed in',
  POWERED_BY: 'Powered by',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  BACK_TO_LOGIN: 'Back to login',
  FORGOT_PASSWORD_LABEL:
    'Enter your email and we will send you instructions to reset your password',
  BUTTON: {
    SIGN_UP: 'Sign Up',
    SIGN_IN: 'Sign In',
    SEND: 'Send',
    SAVE: 'Save',
    UPDATE_PASSWORD: 'Update Password',
    CANCEL: 'Cancel'
  },
  INPUT: {
    FIRSTNAME: 'First Name',
    LASTNAME: 'Last Name',
    EMAIL: 'Email Address',
    GENDER: 'Gender',
    PHONE: 'Phone',
    MALE: 'Male',
    FEMALE: 'Female',
    ADDRESS: 'Address',
    TECHNOLOGY: 'Technology',
    CREATE_PASSWORD: 'Create Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    PASSWORD: 'Password',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password'
  }
};

export const ROUTES = {
  HOME: '/',
  REGISTER: '/signup',
  LOGIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  DASHBOARD: '/dashboard',
  INTERVIEWS: '/interviews',
  PROFILE: '/dashboard/profile'
};

// Message-Types
export const MessageType = {
  Info: 'INFO',
  Error: 'ERROR',
  Warn: 'WARN',
  Success: 'SUCCESS'
};

// Gender
export const Gender = {
  Male: 1,
  Female: 2
};

export const ROLES = {
  ADMIN: 1,
  HR: 2,
  INTERVIEWER: 3,
  CANDIDATE: 4,
  HR_AND_INTERVIEWER: 5
};

export const StatusCode = {
  BadRequest: 400,
  UnAuthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  AlreadyExists: 409,
  InternalServerError: 500
};

export const INITIAL_PAGE_NUMBER = 1;

export const INITIAL_SIZE = 100;

// Role
export const API_REQUEST = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
};

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const INTERVIEWS_COLUMNS = [
  { field: 'Interviewer Name', header: 'Interviewer Name', class: 'name-col' },
  {
    field: 'Start Date',
    header: 'Date',
    sortBy: 'StartDateOfInterviewRound',
    class: 'text-no-wrap'
  },
  { field: 'HR', header: 'HR' },
  { field: 'InterviewRound', header: 'Interview Round' },
  { field: 'Status', header: 'Status', class: 'status' },
  { field: 'Result', header: 'Result' }
];

export const InterviewType = {
  'Technical Interview - 1': 1,
  Practical: 2,
  'HR Round': 3,
  'Technical Interview - 2': 4,
  'Management Round': 5
};

export const InterviewTypeValue = {
  1: 'Technical Interview - 1',
  2: 'Practical',
  3: 'HR Round',
  4: 'Technical Interview - 2',
  5: 'Management Round'
};

export const PRACTICAL_RESULT_STATUS = {
  1: 'Selected',
  2: 'Not Selected',
  3: 'Further Review'
};

export const HR_OVERALL_FEEDBACK_STATUS = {
  1: 'Selected',
  2: 'Not Selected',
  3: 'Offered',
  4: 'In Discussion',
  5: 'Offer Rejected',
  6: 'Offer Accepted'
};

export const STATUS_INTERVIEW = {
  TECHNICAL_INTERVIEW_1_PENDING: 1,
  TECHNICAL_INTERVIEW_1_SCHEDULE: 2,
  TECHNICAL_INTERVIEW_1_COMPLETE: 3,
  PRACTICAL_PENDING: 4,
  PRACTICAL_SCHEDULE: 5,
  PRACTICAL_COMPLETE: 6,
  HR_PENDING: 7,
  HR_SCHEDULE: 8,
  HR_RESCHEDULE: 21,
  HR_COMPLETE: 9,
  CANCELLED: 10,
  TECHNICAL_INTERVIEW_1_RESCHEDULE: 11,
  PRACTICAL_RESCHEDULE: 12,
  TECHNICAL_INTERVIEW_2_PENDING: 13,
  TECHNICAL_INTERVIEW_2_SCHEDULE: 14,
  TECHNICAL_INTERVIEW_2_RESCHEDULE: 15,
  TECHNICAL_INTERVIEW_2_COMPLETE: 16,
  MANAGEMENT_PENDING: 17,
  MANAGEMENT_SCHEDULE: 18,
  MANAGEMENT_RESCHEDULE: 19,
  MANAGEMENT_COMPLETE: 20
};

export const INTERVIEW_STATUS_LIST = {
  1: 'Technical Interview - 1 Pending',
  2: 'Technical Interview - 1 Schedule',
  11: 'Technical Interview - 1 Reschedule',
  3: 'Technical Interview - 1 Complete',
  13: 'Technical Interview - 2 Pending',
  14: 'Technical Interview - 2 Schedule',
  15: 'Technical Interview - 2 Reschedule',
  16: 'Technical Interview - 2 Complete',
  4: 'Practical Pending',
  5: 'Practical Schedule',
  12: 'Practical Reschedule',
  6: 'Practical Complete',
  17: 'Management Pending',
  18: 'Management Schedule',
  19: 'Management Reschedule',
  20: 'Management Complete',
  7: 'HR Pending',
  8: 'HR Schedule',
  21: 'HR Reschedule',
  9: 'HR Complete',
  10: 'Cancelled'
};

export const INVITATION_STATUS = {
  PENDING: 1,
  ACCEPT: 2,
  REJECT: 3,
  ACCEPT_BY_OTHER: 4,
  AUTO_REJECT: 5,
  RESCHEDULED: 6
};

export const SIDEBAR_MENU = [
  {
    label: 'Home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: ROUTES.DASHBOARD
      },
      {
        label: 'Interviews',
        icon: 'pi pi-fw pi-calendar',
        to: ROUTES.INTERVIEWS
      }
    ]
  }
];
